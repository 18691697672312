import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Space, Tooltip,} from "antd";
import {DownOutlined, FilePdfOutlined, ToTopOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import apiRequest from "../../general/apiRequest";
import {tN} from "../../system/checkTenants";
import {RoomTabs} from "./roomTabs";
import {RoomData} from "./roomData";
import useCredentials from "../../system/UseCredentials";
import CsescaContext from "../../core/AppContext/CsescaContext";
import {useFetchData} from "../../core/Fetch/useFetchData";
import {raveRoomMeta} from "../../rave/Room/RoomMeta";
import {sysRight} from "../../common/cmnRights";

import {getRaveBreadcrumbType} from "../../rave";
import {PrintRoomChoose} from "../../dialog/modalPrintCooseRoom";

export const RoomMain = ({ ...props}) => {
  const [state, setState] = useContext(CsescaContext)
  const [collapseDetail, setCollapseDetail] = useState(false)
  // const [room, setRoom] = useState({...defaultRoom})
  // const [rights, setRights] = useState([])
  // const [isChanged, setIsChanged] = useState(false)
  const navigation = useNavigate()
  const params = useParams()
  const paramsId = params.id
  const {data, rights, persist, recast} = useFetchData(raveRoomMeta, params.id, {})
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant.id
  const breadcrumbType = getRaveBreadcrumbType(data, tenantId)
  useEffect(() => {
    doState()
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])
  const doState = () => setState({
    ...state,
    breadcrumbType: breadcrumbType,
    entityName: "room",
    pageType: "rave",
    entityId: params.id,
    state: "done",
    breadCrumbList: [],
  })
/*
  const onPrint = (event) => {
    const reportUrl = ((!event.ctrlKey)?"/room/sheet/":"/jasper/roomSheet/")
//    apiRequest.post(`/room/sheet/${data.id}`, null, {responseType: "blob"})
    apiRequest.post(reportUrl + data.id, null, {responseType: "blob"})
      .then(doc => {
        const docURL = URL.createObjectURL(doc)
        const anchor = document.createElement("a")
        anchor.href = docURL
        anchor.download = "RM-Doc_" + data.codeNumber + "_" + data.name + ".pdf"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(docURL)
      })
  }
*/
  const onPrintChooseResult = (res) => {
    const reportUrl = "/room/sheet/"
    apiRequest.post(reportUrl + data.id, JSON.stringify(res.values),{responseType: "blob"})
      .then(doc => {
        const docURL = URL.createObjectURL(doc)
        const anchor = document.createElement("a")
        anchor.href = docURL
        anchor.download = "RM-Doc_" + data.codeNumber + "_" + data.name + ".pdf"
        anchor.target = "_blank"
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
        URL.revokeObjectURL(docURL)
      })
  }
  const handleChildChange = ({target}) => {
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(target.name, target.type === "checkbox" ? target.checked : target.value)
  }
  const handleSelectChanges = (name, value) => {
    // const update = (rights.indexOf(sysRight.UPDATE) >= 0)
    if (rights.indexOf(sysRight.UPDATE) >= 0) recast(name, value)
  }
  return <Card
    className={"ces-rave-card"}
    style={(data && !!data.templateId)?{backgroundColor:"#FFFFFA"}: {}}
    title={<><h2><Button type={"text"} icon={<DownOutlined rotate={(!collapseDetail)?0:-90} />} style={{marginRight: ".2em"}} onClick={() => setCollapseDetail(!collapseDetail)} />{tN(tenantId, "Raum")}</h2><i>{data?.codeNumber}</i>{data?.name}</>}
    extra={<Space>
      {(data.projectId)?
        <>
{/*
          <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
            <Button
              type="link"
              icon={<FilePdfOutlined style={{fontSize: "2em"}} />}
              onClick={onPrint}
            />
          </Tooltip>
*/}
          <PrintRoomChoose
            buttonText={"PDF..."}
            buttonIcon={<FilePdfOutlined style={{fontSize: "2em",}} />}
            onResult={onPrintChooseResult}
          />
          <Tooltip title={"Übergeordnetes Element"} color={'cyan'} placement="topRight">
            <Button
              type={"link"}
              icon={<ToTopOutlined style={{fontSize: "2em"}} />}
              onClick={() => {
                if (data.projectId === data.parentId) navigation(`/ras/project/${data.projectId}`)
                else navigation(`/ras/breakdown/${data.parentId}`)
              }}
            />
          </Tooltip>
          </>
        :null}
    </Space>}
  >
    <RoomData
      collapseDetail={collapseDetail}
      room={data}
      data={data}
      rights={rights}
      handleChildChange={(evt) => {handleChildChange(evt)}}
      handleSelectChanges={(name, value) => {handleSelectChanges(name, value)}}
      handleChildBlur={(evt) => {persist()}}
    />
    <RoomTabs
      data={data}
      editable={rights.indexOf(sysRight.UPDATE) >= 0}
      rights={rights}
      room={data}
    />
  </Card>
}
