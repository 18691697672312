import React, {useState} from 'react';
import {Button, Checkbox, Col, Form, message, Modal, Row, Tooltip} from "antd";
import {PrinterOutlined} from "@ant-design/icons";

const printProps = {
  newReport: false,
  caption: false,
  objectData: true,
  planing: false,
  restoration: false,
  visible: false,
}
export const PrintWorkpieceChoose = (
  {
    buttonType = "link",
    buttonIcon = <PrinterOutlined />,
    buttonShape = "default",
    onResult = (value) => message.error(<p>Funktion nicht angelegt</p>,5),
    ...props
  }) => {
  const [state, setState] = useState(printProps)
  const showModal = (event) => setState({...state, visible: true, newReport: event.ctrlKey})
  const handleCancel = () => setState({...printProps})
  const handleOk = () => {
    onResult({
      result: "ok",
      newReport: state.newReport,
      values: {
        objectData: state.objectData,
        caption: state.caption,
        planing: state.planing,
        restoration: state.restoration,
      }
    })
    setState(printProps)
  }
  const handleChanges = ({target}) =>  setState({...state, [target.name]: (target.type === "checkbox") ? target.checked : target.value})
  return <>
    <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
    <Button
      type={buttonType}
      icon={buttonIcon}
      shape={buttonShape}
      onClick={showModal}
      />
    </Tooltip>
    {(state.visible)?
    <Modal
      destroyOnClose={true}
      open={state.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <h3>Druckauswahl</h3><br />
          <Form name="print-choose-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="Objektdaten" name="objectData" style={{marginBottom: 0}}>
              <Checkbox
                name="objectData"
                checked={state.objectData}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Veränderungen/Schäden" name="caption" style={{marginBottom: 0}}>
              <Checkbox
                name="caption"
                checked={state.caption}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Planung" name="planing" style={{marginBottom: 0}}>
              <Checkbox
                name="planing"
                checked={state.planing}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Restaurierung" name="restoration" style={{marginBottom: 0}}>
              <Checkbox
                name="restoration"
                checked={state.restoration}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
      :null}
  </>
}