import React, {useState} from 'react';
import {Button, Checkbox, Col, Form, message, Modal, Row, Tooltip} from "antd";
import {PrinterOutlined} from "@ant-design/icons";

const printRoomProps = {
  qrCode: false,
  objectList: false,
}
export const PrintRoomChoose = (
  {
    buttonType = "link",
    buttonIcon = <PrinterOutlined />,
    buttonShape = "default",
    onResult = (value) => message.error(<p>Funktion nicht angelegt</p>,5),
    ...props
  }) => {
  const [state, setState] = useState(printRoomProps)
  const showModal = (event) => setState({...state, visible: true, newReport: event.ctrlKey})
  const handleCancel = () => setState({...printRoomProps})
  const handleOk = () => {
    onResult({
      result: "ok",
      newReport: state.newReport,
      values: {
        qrCode: state.qrCode,
        objectList: state.objectList,
      }
    })
    setState(printRoomProps)
  }
  const handleChanges = ({target}) =>  setState({...state, [target.name]: (target.type === "checkbox") ? target.checked : target.value})
  return <>
    <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
    <Button
      type={buttonType}
      icon={buttonIcon}
      shape={buttonShape}
      onClick={showModal}
      />
    </Tooltip>
    {(state.visible)?
    <Modal
      destroyOnClose={true}
      open={state.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <h3>Druckauswahl:</h3><br />
          <Form name="print-choose-form-room"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="QR-Code Drucken" name="qrCode" style={{marginBottom: 0}}>
              <Checkbox
                name="qrCode"
                checked={state.qrCode}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Objekt-Liste anhängen" name="objectList" style={{marginBottom: 0}}>
              <Checkbox
                name="objectList"
                checked={state.objectList}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
      :null}
  </>
}