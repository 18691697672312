import React, {useEffect, useState} from 'react'
import {Input, Image, Button, Popconfirm, Card, Modal} from 'antd'
import { Row, Col } from 'antd'
import {
  FileWordTwoTone,
  FileExcelTwoTone,
  FilePdfTwoTone,
  FileZipTwoTone,
  FileTwoTone,
  FileExclamationTwoTone,
  DeleteOutlined,
  DownloadOutlined,
  SearchOutlined,
  DragOutlined
} from "@ant-design/icons"
import {sysRight} from "../common/cmnRights"
import apiRequest, {apiUrl} from "../general/apiRequest"
import PanelDndContainer, {parentTypes} from "./panelDndContainer";
import {defFallback} from "../images/imageMapHover";
import {CsescaUpload} from "../images/CsescaUpload";
import {preview} from "../images/CsescaPreviewOverlay";

const apiUrlMediaShow           = "/media/panelImage"
const apiUrlMediaChange         = "/media/changeUpload"
const apiUrlDeleteMediaInRef    = "/media/deleteMediaInRef"
const apiUrlSaveMediaName       = "/media/saveMediaName"
const apiUrlSaveMediaRefName    = "/media/saveMediaRefName"

const defMedia = {
  id: "",
  available: false,
  mediaRefId: "",
  mediaId: "",
  imageURL: "",
  name: "",
  mediaName: "",
}

export const PanelMedia = ({projectId, itemId, editable, fromTemplate = false, rightsParent, extra, ...props}) => {
//  const {projectId, itemId, editable, fromTemplate, rightsParent, extra} = props
  const [mapVisible, setMapVisible] = useState(false)
  const [media, setMedia] = useState(defMedia)
  const [fileList, setFileList] = useState([])
  useEffect(() => {getMediaImage(itemId)}, [itemId])
  const changeRequest = ({ file, onSuccess, action }) => {
    const frmData = new FormData()
    frmData.append("file", file)
    frmData.append("id", itemId)
    frmData.append("parentId",  props.workId)
    frmData.append("projectId", projectId)
    frmData.append("parentType", props.parentType)
    frmData.append("name", media.name)
    apiRequest.post(action, frmData, {
      credentials: "include",
      headers: {
       'Content-Type': 'multipart/form-data',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Method": "*",
      }
    })
      .then(rd => setMedia(rd))
      .catch(error => console.log("PanelMedia in TitleImage Save: " + error))
  }
  const saveMediaRefName = async () => {
    await apiRequest.put(apiUrlSaveMediaRefName, JSON.stringify({id:itemId, name: media.name}))
  }
  const saveMediaName = async () => {
    await apiRequest.put(apiUrlSaveMediaName, JSON.stringify({id:media.mediaId, name: media.mediaName}))
  }
  const getMediaImage = async mediaId => {
    const rd = await apiRequest.get(apiUrlMediaShow + "/" + mediaId )
    if (rd.available) {
      setMedia(rd)
      setFileList([{uid: rd.mediaRefId, name: rd.name, status: "done", url: apiUrl + "/" + rd.imageURL}])
      // this.setState({media: rd, fileList : [{uid: rd.mediaRefId, name: rd.name, status: "done", url: apiUrl + "/" + rd.imageURL}]})}
    }
    else
      setMedia(rd)
  }
  //TODO Check mediaId
  const deleteMediaInRef = async mediaId => {
    await apiRequest.delete(apiUrlDeleteMediaInRef + "/" + mediaId)
    setMedia(defMedia)
    setFileList([])
    // this.setState({media: {...this.state.media, imageURL: "", name: ""}, fileList: []})
  }
  const handleRemoveMediaInRef = () => {
    deleteMediaInRef(itemId)
  }
//  const handleCancel = () => this.setState({ previewVisible: false })
  const handleChange = ({ fileList }) => {setFileList(fileList)}
  const createSvgEnvelope = (envelop) => {
    return {__html: envelop};
  }
  const doCancel = () => {
    setMapVisible(false)
  }
  const doShowMap = () => {
    setMapVisible(media?.imageURL !== "" && media?.svgEnvelope !== "")
  }
  const modalPreview = () => {
    if (media && mapVisible) return <Modal
      titleName={null}
      width={"900px"}
      open={mapVisible}
      onCancel={doCancel}
      closable={false}
      footer={null}
      style={{ top: 150, left: 50}}
      styles={{body:{padding: 0,}}}
      // bodyStyle={{padding: 0,}}
    >
      <figure>
        <svg
          version="1.1" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1500 1125"
        >
          <image width="1500" height="1125" xlinkHref={apiUrl + "/" + media.imageURL}></image>
          <svg
            viewBox="0 0 1500 1125"
            dangerouslySetInnerHTML={createSvgEnvelope(media.svgEnvelope)}
          >
          </svg>
        </svg>
      </figure>
    </Modal>
  }
  const imageName = () => {
    return (editable)
      ? <Input
        name={"titel"}
        value={media.mediaName}
        style={{marginTop: "1em", width: "100%"}}
        onChange={(e) => {setMedia(prevState => {return {...prevState, mediaName: e.target.value}})}}
        onBlur={() => saveMediaName()}
        readOnly={!editable || !update}
        disabled={!update || !projectId}
      />
      : <div style={{marginTop: "1em"}}>{media.mediaName}</div>
  }
  const renderElement = () => {
//    const { fileList/*, previewVisible, previewImage, previewTitle*/ } = this.state
    const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
    const uploadButton = (<div>
      <Image width={"100%"} src="error"
             fallback={defFallback}
      />
    </div>)
    const acceptFiles = ".jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.dwg,.dxf,.zip,.step,.stp,.mov,.mp4,.avi"
    const extension = (media && media.available && media.imageURL)?media.imageURL.split(".").pop():""
    const images = new Set(["jpg","jpeg","png"])
    const isImage = images.has(extension)
    let imgElement = ""
    if (!(media.imageURL !== "") && editable && update) {
      imgElement = <div className="title-image">
        <CsescaUpload
          accept={acceptFiles}
          disabled={!projectId}
          name="listImage"
          listType="picture"
          fileList={fileList}
          action={apiUrlMediaChange}
          customRequest={changeRequest}
          onRemove={() => {handleRemoveMediaInRef()}}
          onChange={handleChange}
        >
          {fileList.length > 0 ? null : uploadButton}
        </CsescaUpload>
      </div>
    } else if (isImage) {
      if (editable)
      imgElement = <div className="title-image">
        <Image
          src={apiUrl + "/" + media.imageURL}
          preview={preview({update: update, mediaRefId: media.mediaRefId, deleteImage: deleteMediaInRef})}
          // preview={{
          //   mask: <div>
          //     <ExpandOutlined style={{fontSize: "2em"}} />
          //     <div
          //       style={{position:"absolute", top:0, right:0}}
          //     >
          //       <Popconfirm
          //         placement="topRight"
          //         title={"Möchten Sie die Zuordung des Bildes löschen"}
          //         okText="Ja"
          //         cancelText="Nein"
          //         onConfirm={() => {deleteMediaInRef(media.mediaRefId)}}
          //         onCancel={(e) => {e.stopPropagation()}}
          //         cancelButtonProps={{danger: false, type: "primary"}}
          //         okButtonProps={{danger: true, ghost: true}}
          //       >
          //         <Button
          //           type="text"
          //           style={{position:"absolute", top:0, right:0}}
          //           onClick={(e)=>{e.stopPropagation()}}
          //         ><DeleteOutlined style={{fontSize: "1.5em"}} /></Button>
          //       </Popconfirm>
          //     </div>
          //
          //   </div>
          // }}
          onClick={doShowMap}
          fallback={defFallback}
        />
      </div>
      else
        imgElement =
          <Row>
            <Col span={24}>
              <div className="title-image">
                <Image
                  src={apiUrl + "/" + media.imageURL}
                  preview={preview({update: update, mediaRefId: media.mediaRefId, deleteImage: deleteMediaInRef})}
                  // preview={update}
                  onClick={doShowMap}
                  fallback={defFallback}
                />
                {modalPreview()}
              </div>
            </Col>
          </Row>
    } else {
      const DeleteConfirm = () => (editable)?<Popconfirm
        placement="topRight"
        title={"Möchten Sie die Zuordung des Bildes löschen"}
        okText="Ja"
        cancelText="Nein"
        onConfirm={() => {deleteMediaInRef(media.mediaRefId)}}
        onCancel={(e) => {e.stopPropagation()}}
        cancelButtonProps={{danger: false, type: "primary"}}
        okButtonProps={{danger: true, ghost: true}}
      >
        <Button
          type="text"
          style={{position:"absolute", top:0, right:0}}
          onClick={(e)=>{e.stopPropagation()}}
        ><DeleteOutlined style={{fontSize: "1.5em"}} /></Button>
      </Popconfirm>:null
      const iconStyle = (editable)?{ style: {fontSize: '64px', margin: "0.1em", color: "#08c"}, twoToneColor: "red"}:{ style: {fontSize: '24px', margin: "0.1em", color: "#08c"}, twoToneColor: "red"}
      switch (extension) {
        case "doc":
        case "docx":
        case "odt":
          imgElement = <><FileWordTwoTone {...iconStyle}/><DeleteConfirm /></>
          break
        case "xls":
        case "xlsx":
        case "ods":
          imgElement = <><FileExcelTwoTone {...iconStyle} /><DeleteConfirm /></>
          break
        case "dwg":
        case "dxf":
          imgElement = <><FileExclamationTwoTone {...iconStyle} /><DeleteConfirm /></>
          break
        case "pdf":
          imgElement = <><FilePdfTwoTone {...iconStyle} /><DeleteConfirm /></>
          break
        case "zip":
          imgElement = <><FileZipTwoTone {...iconStyle} /><DeleteConfirm /></>
          break
        default:
          imgElement = <><FileTwoTone {...iconStyle}/><DeleteConfirm /></>
      }

    }
    imgElement = <>{imgElement}{imageName()}</>
    return imgElement
  }
  const renderAdditionalHeaderComponents = (editable, update, fromTemplate) => {
    return <Input
      name={"titel"}
      value={media.name}
      style={{width: "100%"}}
      // onChange={(e) => {this.setState({media: {...media, name: e.target.value}})}}
      onChange={(e) => {setMedia(prevState => {return {...prevState, name: e.target.value}})}}
      onBlur={() => saveMediaRefName()}
      readOnly={!editable || !update}
      disabled={!update || !projectId}
    />
  }
  const renderCartTitle = (editable, update, fromTemplate) => {
    if (editable)
      return {title:<Row gutter={[16,16]}>
          {(!fromTemplate && editable)
            ?<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
            :null
          }
          <Col span={9}>
            {renderAdditionalHeaderComponents(editable, update, fromTemplate)}
          </Col>
        </Row>,
        extra: <>
          <Button type={"text"} icon={<SearchOutlined />} disabled={!projectId} />
          {(media.imageURL !== "" && editable && update) ?
            <Button type={"text"} icon={<DownloadOutlined />} href={apiUrl + "/" + media.imageURL} target={"_blank"} rel="noreferrer" />
            : null}
          {extra}
        </>,
      }
    else return {title: null}
  }
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  return (
    <>
{/*
      <div className={"ant-card ant-card-bordered ant-card-small"}>
        <div className={"ant-card-head"}>
          <div className={"ant-card-head-wrapper"}>
            <div className={"ant-card-head-title"}>
              <Row gutter={[16,16]}>
                {(!fromTemplate && editable)
                  ?<Col span={1}>{(!fromTemplate)?<DragOutlined style={{ cursor: 'grab', color: '#999' }} />:null}</Col>
                  :null
                }
                <Col span={5}>
                  <Input
                    name={"name"}
                    value={text.name}
                    onBlur={() => {handleChildBlur()}}
                    onChange={(evt) => {handleChildChange(evt)}}
                    readOnly={!props.editable || !update}
                    disabled={!update}
                  />
                </Col>
                <Col span={6}>
                  {(!fromTemplate)
                    ?<SelectTextType
                      textTypeEntityId={getEntityId(props.parentType)}
                      allowClear
                      value={text.textTypeId}
                      style={{width: "100%"}}
                      onChange={(evt) => {handleSelectChanges("textTypeId", evt)}}
                      onBlur={() => {handleChildBlur()}}
                      readOnly={!editable || !update}
                      disabled={!update}
                    />
                    :null}
                </Col>
              </Row>
            </div>
            <div className={"ant-card-head-extra"}>
              {(editable)?props.extra:null}
            </div>
          </div>
        </div>
        <div className={"ant-card-body"}>
          <Row gutter={[16,16]}>
            {(!isLigniteTenant(tenant))
              ?<Col span={24} style={{textAlign: "right"}} >
                <SelectTextFunction
                  value={text.formatId}
                  //                 style={{width: "100%"}}
                  onChange={(evt) => {handleSelectChanges("formatId", evt)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
              </Col>
              :null}
            {(text.formatId === "493189fc-0d87-4881-b983-14c5bb761ce9")
              ?<Col span={24} >
                <ReactQuill
                  value={text.text}
                  onChange={(str, delta, source, editor) => {handleQuillChange("text", str, delta, source, editor)}}
                  onBlur={handleQuillBlur}
                  modules={{toolbar: !!(editable)}}
                  //            onBlur={(range, source, ue) => {}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
                {editable?<div style={{width: "100%", textAlign: "right", }}>{count}</div>:null}
              </Col>
              :<Col span={24}>
                <Input.TextArea
                  name={"text"} value={text.text}
                  maxLength={maxLength}
                  showCount={editable}
                  autoSize={editable?{ minRows: 6, maxRows: 10 }:{ minRows: 4, maxRows: 124 }}
                  style={{resize: "none"}}
                  onChange={(evt) => {handleChildChange(evt)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
              </Col>}
          </Row>
        </div>
      </div>
*/}
  <Card {...renderCartTitle(editable, update, fromTemplate)}>
      <Row gutter={[16,16]}>
        <Col span={4} className="panel-image">
              {renderElement()}
        </Col>
        <Col span={18}>
          {!editable
            ?<Row gutter={[16,16]} style={{marginBottom: "1em"}}>
              <Col span={8}>
                {renderAdditionalHeaderComponents(editable, update, fromTemplate)}
              </Col>
                {(media.imageURL !== "" /* && update */) ?
                  <Col span={1}>
                    <Button type={"link"} icon={<DownloadOutlined />} href={apiUrl + "/" + media.imageURL} target={"_blank"} rel="noreferrer" />
                  </Col>
                  : null}
            </Row>
            :null
          }
          <Row gutter={[16,16]}>
            <Col span={24}>
              <PanelDndContainer
                fromTemplate={fromTemplate}
                projectId={projectId}
                workId={itemId}
                editable={editable}
                rightsParent={rightsParent}
                parentType={parentTypes.image}
                panelTypes={["text"]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
  </Card>
  </>
  )
}