import React, {useEffect, useState} from 'react'
import {Row, Col, Input} from 'antd'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import {sysRight} from "../common/cmnRights"
import {getEntityId} from "../general/Entities"
import apiRequest from "../general/apiRequest"
import {DragOutlined} from "@ant-design/icons";
import {isLigniteTenant} from "../system/checkTenants";
import {SelectTextType} from "../common/cmnSelectTextType";
import {SelectTextFunction} from "../common/SelectTextFunction";
import useCredentials from "../system/UseCredentials";


const apiUrlTextShow               = "/text/show"
const apiUrlTextSave               = "/text"
//const apiUrlTexttypeShowEntity     = "/texttype/showbyentity"

const defText = {
  id: "",
  active: true,
  position: -1,
  parentId: "",
  formatId: "cf68b060-e6db-46d8-b50c-e7d4c4299a5d",
  textTypeId: "",
  name: "loading...",
  text: ""
}
export const TextPanel = ({editable = false, extra, fromTemplate = false, itemId, parentType, rightsParent = [],}) => {
  const [text, setText] = useState({...defText})
  const [count, setCount] = useState(0)
  const [isChanged, setIsChanged] = useState(false)
  const [credentials] = useCredentials()
  const tenantId = credentials?.tenant?.id
  useEffect(() => {loadText(itemId)}, [itemId])
  const loadText = (textId) => {
    apiRequest.get(`${apiUrlTextShow}/${textId}`)
      .then((rd) => {
        if (rd && rd.state && rd.data) setText(rd.data)
      })
      .catch(error => console.log("Error Load in MainText: " + error))
  }
  const handleSave = () => {
    if (isChanged) {
      apiRequest.put(apiUrlTextSave, JSON.stringify(text))
        .then((rd) => setIsChanged(false))
        .catch(error => console.log("Error Save in MainText: " + error))
    }
  }
  const handleChildBlur = () => {handleSave()}
  const handleSelectChanges = (name, value) => {
    if (editable) {
      setText(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleChildChange = (evt) => {
    if (editable) {
      const name = evt.target.name
      const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
      setText(prevState => {return {...prevState, [name]: value}})
      setIsChanged(true)
    }
  }
  const handleQuillChange = (name, value, delta, source, editor) => {
    setText(prevState => {return {...prevState, [name]: value}})
    setIsChanged(true)
    setCount(editor.getLength()-1)
  }
  const handleQuillBlur = (range, source, ue) => {
   handleSave()
  }
  const maxLength = (!isLigniteTenant(tenantId)) ? null : 1000
  const update = (rightsParent.indexOf(sysRight.UPDATE) >= 0)
  return <div className={"ant-card ant-card-bordered ant-card-small"}>
      <div className={"ant-card-head"}>
        <div className={"ant-card-head-wrapper"}>
          <div className={"ant-card-head-title"}>
            <Row gutter={[16,16]}>
              {(!fromTemplate && editable)
                ?<Col span={1}><DragOutlined style={{ cursor: 'grab', color: '#999' }} /></Col>
                :null
              }
              {(text.formatId !== "cc9d58bb-0073-42fb-a0c0-ee7ec5c1a7dc")
                ?<>
                  <Col span={5}>
                    <Input
                      name={"name"}
                      value={text.name}
                      onBlur={() => {handleChildBlur()}}
                      onChange={(evt) => {handleChildChange(evt)}}
                      readOnly={!editable || !update}
                      disabled={!update}
                    />
                  </Col>
                  <Col span={6}>
                    {(!fromTemplate && editable)
                      ?<SelectTextType
                        textTypeEntityId={getEntityId(parentType)}
                        allowClear
                        value={text.textTypeId}
                        style={{width: "100%"}}
                        onChange={(evt) => {handleSelectChanges("textTypeId", evt)}}
                        onBlur={() => {handleChildBlur()}}
                        readOnly={!editable || !update}
                        disabled={!update}
                      />
                      :null}
                  </Col>
                </>
                :<Col span={11}></Col>
              }
            </Row>
          </div>
          <div className={"ant-card-head-extra"}>
            {(editable)?extra:null}
          </div>
        </div>
      </div>
      <div className={"ant-card-body"}>
        <Row gutter={[16,16]}>
          {(!isLigniteTenant(tenantId))
          ?<>
            <Col span={21} />
            <Col span={3} style={{textAlign: "right"}} >
              <SelectTextFunction
                value={text.formatId}
                style={{width: "100%"}}
                onChange={(evt) => {handleSelectChanges("formatId", evt)}}
                onBlur={() => {handleChildBlur()}}
                readOnly={!editable || !update}
                disabled={!update}
              />
            </Col>
          </>
          :null}
          {(text.formatId === "493189fc-0d87-4881-b983-14c5bb761ce9")
            ?<Col span={24} >
                <ReactQuill
                value={text.text}
                onChange={(str, delta, source, editor) => {handleQuillChange("text", str, delta, source, editor)}}
                onBlur={handleQuillBlur}
                modules={{toolbar: !!(editable)}}
//            onBlur={(range, source, ue) => {}}
                readOnly={!editable || !update}
                disabled={!update}
              />
              {editable?<div style={{width: "100%", textAlign: "right", }}>{count}</div>:null}
            </Col>
            :(text.formatId === "cf68b060-e6db-46d8-b50c-e7d4c4299a5d")
            ?<Col span={24}>
                <Input.TextArea
                  name={"text"} value={text.text}
                  maxLength={maxLength}
                  showCount={editable}
                  autoSize={editable?{ minRows: 6, maxRows: 10 }:{ minRows: 4, maxRows: 124 }}
                  style={{resize: "none"}}
                  onChange={(evt) => {handleChildChange(evt)}}
                  onBlur={() => {handleChildBlur()}}
                  readOnly={!editable || !update}
                  disabled={!update}
                />
              </Col>
            :<Col span={24}></Col>
          }
      </Row>
    </div>
  </div>
}
