/**
 * modal window to choose persons and organisations
 */

import React, {useState} from 'react';
import {Button, Checkbox, Col, Form, message, Modal, Row, Tooltip} from "antd";
import {PrinterOutlined} from "@ant-design/icons";

const printProps = {
  newReport: false,
  objectData: true,
  participant: false,
  restorationGoal: false,
  room: false,
  visible: false,
}

export const PrintProjectChoose = (
  {
    buttonType = "link",
    buttonIcon = <PrinterOutlined />,
    buttonShape = "default",
    onResult = (value) => message.error(<p>Funktion nicht angelegt</p>,5),
    ...props
  }) => {
  const [state, setState] = useState(printProps)
  const showModal = (event) => setState({...state, visible: true, newReport: event.ctrlKey})
  const handleCancel = () => setState({...printProps})
  const handleOk = () => {
//    const {objectData, room, restorationGoal, participant} = state
//    setState((prevState) => {return {...prevState, visible: false}})
    onResult({
      result: "ok",
      newReport: state.newReport,
      values: {
        objectData: state.objectData,
        room: state.room,
        restorationGoal: state.restorationGoal,
        participant: state.participant,
      }
    })
    setState(printProps)
//    setState((prevState) => {return {...prevState, newReport: false, participant: false, objectData: true, restorationGoal: false, room: false,}})
  }
  const handleChanges = ({target}) =>  setState({...state, [target.name]: (target.type === "checkbox") ? target.checked : target.value})
  // const handleChanges = (evt) => {
  //   const name = evt.target.name
  //   const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
  //   setState((prevState) => ({...prevState, [name]: value}))
  // }
  return <>
    <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
      <Button
        type={buttonType}
        icon={buttonIcon}
        shape={buttonShape}
        onClick={showModal}
      />
    </Tooltip>
    {(state.visible)?
    <Modal
      destroyOnClose={true}
      open={state.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <h3>Druckauswahl</h3><br />
          <Form name="print-project-coose-form"
            colon=":"
            layout="horizontal"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="Objektdaten" name="objectData" style={{marginBottom: 0}}>
              <Checkbox
                name="objectData"
                checked={state.objectData}
                onChange={(e) => {handleChanges(e)}}
              />
            </Form.Item>
            {/*
            <Form.Item label="Räume" name="room" style={{marginBottom: 0}}>
              <Checkbox
                name="room"
                checked={this.state.room}
                onChange={(e) => {this.handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Restaurierung" name="restorationGoal" style={{marginBottom: 0}}>
              <Checkbox
                name="restorationGoal"
                checked={this.state.restorationGoal}
                onChange={(e) => {this.handleChanges(e)}}
              />
            </Form.Item>
            <Form.Item label="Mitwirkende" name="participant" style={{marginBottom: 0}}>
              <Checkbox
                name="participant"
                checked={this.state.participant}
                onChange={(e) => {this.handleChanges(e)}}
              />
            </Form.Item>
*/}
          </Form>
        </Col>
      </Row>
    </Modal>
      :null}
  </>
}
export class PrintProjectChooseOld extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      objectData: true,
      room: false,
      restorationGoal: false,
      participant: false,
    }
    this.showModal = this.showModal.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOk = this.handleOk.bind(this)
    this.handleChanges = this.handleChanges.bind(this)
  }
  showModal = () => { this.setState({visible: true}) }
  handleCancel = () => { this.setState({visible: false}) }
  handleOk = () => {
    const {objectData, room, restorationGoal, participant} = this.state
    this.setState({visible: false})
    this.props.onResult({
      result: "ok",
      values: {
        objectData: objectData,
        room: room,
        restorationGoal: restorationGoal,
        participant: participant,
      }
    })
    this.setState({objectData: true, room: false, restorationGoal: false, participant: false,})
  }
  handleChanges = (evt) => {
    const name = evt.target.name
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    this.setState({[name]: value})
  }
  render() {
    const { visible } = this.state;
    return (
      <>
        <Tooltip title={"Erzeugt eine PDF-Ausgabe"} color={'cyan'} placement="topRight" >
          <Button
            type={this.props.buttonType}
            icon={this.props.buttonIcon}
            shape={this.props.buttonShape}
            onClick={this.showModal}
          />
        </Tooltip>
        {(this.state.visible)?
        <Modal
          destroyOnClose={true}
          open={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row>
            <Col span={24}>
              <h3>Druckauswahl</h3><br />
              <Form name="print-project-coose-form"
                colon=":"
                layout="horizontal"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
              >
                <Form.Item label="Objektdaten" name="objectData" style={{marginBottom: 0}}>
                  <Checkbox
                    name="objectData"
                    checked={this.state.objectData}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
                {/*
                <Form.Item label="Räume" name="room" style={{marginBottom: 0}}>
                  <Checkbox
                    name="room"
                    checked={this.state.room}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
                <Form.Item label="Restaurierung" name="restorationGoal" style={{marginBottom: 0}}>
                  <Checkbox
                    name="restorationGoal"
                    checked={this.state.restorationGoal}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
                <Form.Item label="Mitwirkende" name="participant" style={{marginBottom: 0}}>
                  <Checkbox
                    name="participant"
                    checked={this.state.participant}
                    onChange={(e) => {this.handleChanges(e)}}
                  />
                </Form.Item>
*/}
              </Form>
            </Col>
          </Row>
        </Modal>
          :null}
      </>
    )
  }
}
PrintProjectChooseOld.defaultProps = {
  buttonType: "link",
  buttonIcon: <PrinterOutlined />,
  buttonShape: "default",
  onResult: (value) => message.error(<p>Funktion nicht angelegt</p>,5),
}